.vs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.vs-container p {
    font-size: 17px;
    line-height: 111.25%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #041c32;
    /* background-color: #041C32; */
    justify-content: center;
    height: 70px;
}

.vsImagesAll {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.vsImages {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;

}
::-webkit-scrollbar::content {
    background-color: #d5d1d1;
    width: 8px;
    height: 4px;
    /* border-radius: 5px; */
}

::-webkit-scrollbar-thumb::content {
    background-color: #686868;
    width: 8px;
    height: 4px;
    /* border-radius: 5px; */
}

.vsImages img {
    width: 85%;
    height: 50%;
    /* padding-bottom: 20px; */
}

.vsImages h2 {
    font-size: 20px;
    line-height: 111.25%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #041c32;
    padding-bottom: 15px;
}

.titleRef {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}


/* .LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
  }
  
  .is-visible {
    opacity: 1;
  } */



@media only screen and (max-width: 1060px) {
    .vs-container p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 960px) {
    .vs-container p {
        font-size: 13px;
    }
}

@media only screen and (max-width: 880px) {
    .vs-container p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 800px) {
    .vs-container p {
        font-size: 11px;
    }
}

@media only screen and (max-width: 750px) {
    .vs-container p {
        font-size: 10px;
    }
}

@media only screen and (max-width: 650px) {
    .vs-container p {
        font-size: 9px;
    }
}