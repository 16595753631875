.upload-input {
    width: 100vw;
    max-width: 900px;
    height: 310px;
    top: -390px;
    left: 3009px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 2px;
    background: #ECB3651A;
    border: 2px dotted #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
 /* top: -63px;
    left: 3009px; */
    gap: 5px;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* Adjust this value to add space between lines */
    letter-spacing: 0.02em;

    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
}

  
  .file-list {

    display: flex;
    /* flex-direction: row; */
    align-items: center;
    flex-wrap: wrap;
    /* border: solid; */
    margin-top: 35px;
    gap: 10px;
    justify-content: center;

  
  }
  #file-list-container{
    overflow-y: auto;
    height: 315px;
    justify-content: flex-start;
    padding-top: 10px;
    overflow-y: auto;
    padding-top: 10px;
  }

  .file-list li {
    flex-basis: calc(20% - 10px); 
    margin-bottom: 10px;
    list-style: none;
  }
  
  /* Add any additional styles for file icons, names, and buttons */
  

.upload-img {
    width: 99.79px;
    height: 100px;
    top: -335px;
    left: 3429.12px;
    padding: 14.06px, 3.08px, 14.06px, 6.08px;

}

.text-fin {
    font-family: Nunito;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-align: center;
}

.text-gras {
    font-family: Nunito;
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-align: center;

    /* font-family: Nunito;
font-size: 17px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.03em;
text-align: center;

 */

}


.fileUploader .fileContainer {
    background: none;
    box-shadow: none;
}

.infos {
    color: #00000099;
    width: 1000px;
    /* height: 102px; */
    top: -63px;
    left: 3009px;
    gap: 5px;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px; /* Adjust this value to add space between lines */
    letter-spacing: 0.02em;
    text-align: center;

}


/* .black-line {
  border-top: 1px solid black;
  margin: 4px 0;
} */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}