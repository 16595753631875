@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);

.container-pricing-per-pic {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /**/
    width: 345px;
    height: 111px;
    /* top: -96px;
left: 4874px; */
    border-radius: 3px;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
linear-gradient(0deg, #FFFFFF, #FFFFFF); */
    border: 1px solid #00000066;
    box-shadow: 0px 1px 12px 0px #00000033;
    cursor: pointer;

}


.infos-pricing-per-pic {
    max-width: 250px;
    max-height: 101px;
    display: flex;
    flex-direction: column;
    padding-right: 19px;

}

.title-pricing-per-pic {
    max-width: 235px;
    max-height: 101px;
    top: -71px;
    left: 5029px;
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
    padding-bottom: 7px;

}


.list-pricing-per-pic {
    display: flex;
    flex-direction: column;
    max-width: 235px;
    max-height: 101px;

}

.list-pricing-per-pic span {

    /* font-family: Poppins; */
    font-size: 13px;
    /* font-weight: 600; */
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    /* color: #666669; */
    font-weight: bold;
    color: #00000099;
    font-family: 'Raleway', Arial, sans-serif;
}


.choice {
    margin-top: 45px;
    width: 314px;
    /* height: 19px; */
    font-family: Nunito;
    font-size: 25px;
    font-weight: 800;
    /* line-height: 19px; */
    letter-spacing: 0.02em;
    text-align: center;
    /* text-decoration: underline; */
    /* border: solid; */
    width: 100%;
}

.container-pricing-per-pic {
    transition: background-color 0.5s ease, color 0.5s ease;
  }
  
  .container-pricing-per-pic:hover {
    background-color: orange;
    color: white;
  }
  
  .container-pricing-per-pic:hover .infos-pricing-per-pic h1,
  .container-pricing-per-pic:hover .list-pricing-per-pic span {
    color: white;
  }