.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 50px;
}

.profile-title h3 {
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #041c32;
  font-size: 27px;
  line-height: 111.25%;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: center;
}

.profile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 806px; */

}

.invisible {
  visibility: hidden;
}

.filter-credit {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1040px;
  width: 100vw;
  height: 100%;
  flex-wrap: wrap;
}
@media screen and (max-width:650px) {
  .filter-credit {

  }
}

.profile-title p {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.08em;
  text-align: right;
  align-self: flex-end;
}

.profile-message {
  width: 806px;
  height: 226px;
  border-radius: 3px;
  background: #F8F7F7;
  border: 1px solid #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 250px auto;
}

.profile-message p {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #041C3299;

}


.btns-container {
  display: flex;
  margin-top: 50px;
  max-width: 1050px;
  width: 100%;
}

.btns-container button {
  /* background-color: transparent; */
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.btns-container button:last-child {
  margin-right: 0;
}

.btns-container button svg {
  color: #000;
  margin-right: 5px;
}