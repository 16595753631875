.order-desc{
    display: grid;
    gap: 11px;
    height: 100%;
    align-items: center;

}

.descp-retour{
    /* max-width: 961px;
    width: 100%; */
    height: 149px;
    /* top: 71px;
    left: 1454px; */
    border-radius: 3px;
    background: #F8F7F780;
    border: 1px solid #00000033;
    margin-bottom: 50px;
    overflow-y: scroll;
    max-width: 1040px;
    width: 100%;

}

.vertical-line {
    border-left: 1px solid #00000033;
    height: 96px;
    margin: 26px 0;
  }

  .loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.8); /* Light gray background with some transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999999999999;
  }
  
  .order-desc span{
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  
  .order-desc h4{
    color: black;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-align: left;
  
  }

  .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust minmax value as needed */
  gap: 10px; /* Adjust gap value as needed */
}

.grid-item {
  width: 100%;
  height: 100%;
}

.grid-image, .grid-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullscreen-image {
  width: 100%;
  height: 100%;
}
.retour-message {
  max-width: 1041px;
}

.grid-container {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  overflow-y: auto;
  /* height: 485px; */
  height: 100%;
  width:100vw
}

.grid-item {
  width: 100%;
  height: 100%;
}

.grid-image, .grid-video {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  height: 233px;
}

.fullscreen-image {
  width: 100%;
  height: 100%;
}

.document-container{
  border: solid 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  height: 100%;
}

.filename{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  background: #d7cece;

}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
  overflow: hidden; /* Prevent scrolling */
}

.fullscreen-image {
  max-width: 90%; /* Adjust image size if needed */
  max-height: 90vh; /* Adjust image size if needed */
  object-fit: contain; /* Ensure image aspect ratio is maintained */
  border-radius: 8px; /* Optional: Add some border radius for aesthetics */
}