.retour-message {
  /* width: 961px;
  height: 323px; */
  border-radius: 3px;
  background: #F8F7F7;
  border: 1px solid #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 0 auto;
}

/* OrderRenderComponent.css */

/* Styles for the custom scrollbar track */
/* ::-webkit-scrollbar {
  width: 9px;
  height: 8px;
} */

/* Styles for the custom scrollbar thumb */
/* ::-webkit-scrollbar-thumb {
  border: 0.5px solid;
  border-radius: 4px;
} */

/* Styles for the custom scrollbar thumb on hover */
/* ::-webkit-scrollbar-thumb:hover {
  color: linear-gradient(0deg, #B3B3B3, #B3B3B3);
} */


.btn-download {
  width: 136px;
  height: 31px;
  top: 11px;
  left: 2274px;
  border-radius: 3px;
  background: #041C32;
  box-shadow: 1px 1px 4px 0px #00000040;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 50px;
display: flex;
padding: unset;
font-size: 15px;
}

.admin-order-imgs{
  display: flex;
  flex-direction: column;
  align-items: center
}


.fullscreen-overlay {
  position: fixed;
  top: 120px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}





.images-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 10px;
  height: 500px;
  width: 100vw;
}

.image-card {
  border: 1px solid #ddd;
  /* padding: 10px; */
  /* display: grid; */
  gap:15px;
 
}

.image-card img {
  width: 100%;
  height: auto;
  height: 233px;
  object-fit: cover;
  
}
.image-card span{
  font-family: Nunito;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
}

.delivery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-container {
  display: flex;
  width: 1041px;
  overflow-x: auto;  /* Ajoutez une barre de défilement horizontale en cas de dépassement */
  white-space: nowrap;
  margin : 20px 0 ;
}

.image-card {
  margin-right: 10px;  /* Ajoutez de l'espace entre les cartes d'images si nécessaire */
}

.image-card h4{
  color: black;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-align: left;

}

.validated-order-message {
  width: 715px;
  height: 95px;
  border-radius: 3px;
  background: #edf7ec;
  border: 1px solid #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto 30px;
}

.validated-order-message p {
  font-family: Nunito;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #041C3299;

}