.login-container {
    margin: 50px 0 250px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 28px; */
    /* border: solid 1px; */
    padding: 43px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    margin: 0 5px;
  }
  .title {
    padding-bottom: 20px;
    max-width: 632.07px;
    width: 100%;
    top: -496px;
    left: -315px;
    font-family: Nunito;
    font-size: 27px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: center;
    text-transform: uppercase;
  }
  
  .login-input {
    max-width: 355px;
    width: 100%;
    height: 53px;
    border-radius: 3px;
    background: #F4F4F4;
    margin: 10px;
    border: solid 1px;
    padding-left: 10px;
    }
    
  .login-input input:first-child {
    top: -425px;
    left: -177px;
  }
  
  .login-input input:last-child {
    top: -425px;
    left: -177px;
  }
  
  .submit-btn-login {
    background: #ECB365;
    color: #041C32;
    width: 170px;
    height: 53px;
    border-radius: 3px;
    margin-top: 10px;
    max-width: 355px;
    width: 100%;
  }
  
  .signup-link {
    top: -20px;
    left: -138px;
    color: #044C7B;
    display: block;
    margin-top: 10px;
  }
  
 .MuiInput-input::placeholder {
    margin-left: -10px !important;
  }