.footerMainContent {
    background-color: #041c32;
    z-index: 3;
    /* background-image: url("../../assets/img/BG_dagnouna.png");
    background-size: 1000px;
    background-repeat:no-repeat ; */
    /* width: 100%; */
}

.customFooterStyle {
    background-image: url("../../assets/img/BG_dagnouna.png");
    background-size: cover; 
    background-position: center;
    padding-bottom: 30px; 
    /* border: solid;  */
    padding-left: 20px;
    padding-right: 20px;
  }

.allTogether {
    display: flex;
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    align-items: flex-start;
    padding: 30px 0 30px 0;
    justify-content: space-between;
}

p.email {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 111.25%;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding: 20px 0 20px 0;
}

p.Phonenumber {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 111.25%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ecb365;
    margin: 0;
    padding: 0 0 20px 0;
}

.socialMedia {
    display: flex;
    align-items: center;
    gap: 10px;
}

.socialMedia img {
    padding: 0 5px 20px 5px;
}

.footerMenu {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width:1050px) {
    .footerMenu{
        flex-direction: column;
        gap: 25px;
    }
    .allTogether{
        flex-direction: column;
        align-items: center;
    }
  }
.footerMenu a {
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.9);
    padding: 0 20px 0 20px;
    text-decoration: none;
}