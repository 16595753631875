.credit-element {
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

@media screen and (max-width:500px) {
    .credit-element {
        flex-direction: column;
        gap: 8px;
        align-items: center;
    }
}

.credit {
    width: 200px;
    height: 17px;
    top: 874px;
    left: 3010px;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-align: center;
}

.totals {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gray-total {
    max-width: 227px;
    width: 100%;
    margin: 15px 0;
    color: #00000099;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-align: center;
}

.last-total {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-align: left;
    color: #18BD5B;
}