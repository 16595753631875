.changes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.changes-container h1 {
    width: 504.95px;
    height: 22px;
    top: 262px;
    left: 1737.55px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-align: center;
    color: #041C32;
    margin-bottom: 20px;
}

.changes-container p {
    width: 455px;
    height: 23px;
    top: 311px;
    left: 1763px;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.065em;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 20px;
    color: #041C32B2;

}

.changes-container textarea  {
    width: 951px;
    height: 237px;
    top: 376px;
    left: 1520px;
    border-radius: 3px;
    border: 1px solid #041C3299
}

.changes-container button {
    width: 165px;
    height: 40px;
    border-radius: 3px;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.015em;
    text-align: center;
    white-space: nowrap;
    margin-top: 10px;
}