@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);

.snip1214 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  /* width: 900px; */
  width: 100%;
  max-width: 1000px;
  margin: 10px;
  margin-top: 50px;
  display: flex;
    align-items: center;
    justify-content: center;
}

.snip1214 .plan {
  border-radius: 12px;
  margin: 0;
  padding: 10px 0 40px 0;
  /* width: 25%; */
  max-width: 250px;
  width: 100%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snip1214 header {
  position: relative;
}

.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}

.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}

/* .snip1214 .plan-price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
} */
.snip1214 .plan-price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
}

.snip1214 .price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 600;
  font-size: 19px;
  color: #000000;
  margin-top: 10px;
}

/* .snip1214 .priceperpic {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #000000;
} */
.snip1214 .priceperpic {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 19px;
  color: #000000;
}
.snip1214 .photo {
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 10px;
  color: #000000;
}

.snip1214 .plan-type {
  opacity: 0.6;
}

.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}

.snip1214 .plan-features li {
  padding: 5px 5%;
}

.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}

.highlighted {
  font-weight: bold;
  color: #00000099
}

.highlighted-first {
  font-weight: 800;
  color: #FF9401;
  font-size: 25px;
}

.snip1214 .plan-select a {
  background-color: #FF9401;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  font-weight: 500;
  display: inline-block;
  border-radius: 8px;

}

.snip1214 .plan-select-highlighted a {
  background-color: #ffffff;
  color: #FF9401;
  border: 1px solid #FF9401;
  border-radius: 8px;
  text-decoration: none;
  padding: 0.5em 1em;
  font-weight: 500;
  display: inline-block;
}

.snip1214 .plan-select a:hover {
  background-color: #ffffff;
  color: #FF9401 !important;

  border: 1px solid #FF9401;
}

.snip1214 .plan-select-highlighted a:hover {
  background-color: #FF9401;
  color: #ffffff !important;
}

.snip1214 .featured {
  margin: 15px 0;
  color: #34495e;
  box-shadow: 0 0 20px rgb(0 0 0 / 25%);
  z-index: 1;
}

.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #000000;
}

.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}

.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
}

.plan-cost .Pack-title {
  margin: 0;
}

.plan .Pack-title {
  margin: -10px ;
  color: #000000;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 1em;
}


/**********************************************/
.ribbon {
  position: absolute;
  padding-inline: 10px;
  border-bottom: var(--f) solid #0005;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
}

.left {
  left: calc(-1 * var(--f));
  border-right: var(--r) solid #0000;
  clip-path: polygon(100% 0,
      0 0,
      0 calc(100% - var(--f)),
      var(--f) 100%,
      var(--f) calc(100% - var(--f)),
      100% calc(100% - var(--f)),
      calc(100% - var(--r)) calc(50% - var(--f) / 2));
}



/**********************************************/
.indications {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  max-width: 900px;
  width: 100%;
}

.indications span {
  margin-bottom: 15px;
  max-width: 989px;
  width: 100%;
  /* height: 19px; */
  top: 640px;
  left: 4639px;
  font-family: Nunito;
  font-size: 15px;
  font-weight: 800;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #000000;
  text-decoration: underline;



}

/* @media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
  }

  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 5px;
  }

  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }

  .snip1214 .featured {
    margin-top: 0;
  }
}

@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
} */


.owl-item {
display: flex;
justify-content: center;
/* width: 300px !important; */
}


.cursor-pointer:hover {
  cursor: pointer;
}
