.headerTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 41px;
    background-color: black;
    color: #f5deb3;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
}

.headerTop p {
    font-size: 12px;
    margin: unset !important;
}

.phoneNumber {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.phoneNumber p {
    margin: unset !important;
}

.phoneNumber img {
    width: 17px;
    height: 20px;
}

.all {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.BG {
    background-size: cover;
    background-position: center;
    width: 100%;
}

.navbar-menu {
    display: flex;
}

nav {
    background: #041C32;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 29px; */
}

.logo img {
    font-size: 35px;
        width: 165px;
    height: 72px;
    cursor: pointer;


}

label.logo:hover {
    color: #5eb9ff;
}

nav ul {
    float: right;
    /* margin-right: 100px; */
    margin-bottom: unset;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

nav ul li {
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
}

nav ul li a {
    /* width: 70px; */
    height: 20px;
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.9);
    padding: 0 20px 0 20px;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: white;

    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.nav-link-btn{
    color:  #041C32 ;
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-decoration: none;
}
.active,
a:hover {
    color: #044c7b !important;
    transition: 0.5s;
}


#order {
    width: fit-content;
    padding: 5px;
    height: 29px;
    display: flex;
    justify-content: center;
    background: #ecb365;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}
#login {
    width: fit-content;
    padding: 5px;
    height: 29px;
    display: flex;
    justify-content: center;
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}

/* .checkbtn1 {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
} */
.checkbtn {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
    width: 100%;
    position: sticky;

}

#check {
    display: none;
    
}

/* header.css */

/* ... (existing styles) */

@media screen and (max-width: 1021px) {
    /* .btns {
      display: none; 
    } */
  }
/**/  
@media (max-width: 952px) {
    .headerTop{
        display: none;
    }
    label.logo {
        font-size: 30px;
        /* padding-left: 50px; */
    }

    nav ul li a {
        font-size: 16px;
    }
}

#loginbtn {
    cursor: pointer;
}

@media (max-width: 1250px) {
    .headerTop{
        display: none;
    }
    #loginbtn {
        display: none;
    }

    .nav {
        display: flex;
        justify-content: center;
    }

    .checkbtn {
        display: block;        
    }

    #nav_ul {
        position: fixed;
        width: 60%;
        height: 100vh;
        background: #041C32;
        top: 0;
        left: -100%;
        text-align: center;
        transition: all 0.5s;
        flex-direction: column;
        padding: unset;
        color: #fff;
    }

    nav ul li {
        display: block;
        margin: 15px 0;
        line-height: 30px;
    }

    nav ul li a {
        font-size: 20px;
        color: #fff;
    }

    #check:checked~ul {
        left: 0;
        width: 345px;
    }
}

@media only screen and (max-width: 1100px) {
    .headerTop p {
        font-size: 12px;
    }

    /* nav ul li {
        margin: 20px 0;
    } */
}

@media only screen and (max-width: 640px) {
    /* .headerTop p {
        font-size: 10px;
    } */
    .headerTop{
        display: none;
    }
}

@media only screen and (max-width: 540px) {
    /* .headerTop p {
        font-size: 8px;
    } */
    .headerTop{
        display: none;
    }
    nav{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    nav ul{
       align-items: start; 
       justify-content: flex-start;
    }
    nav ul li {
        color: #fff;
        font-size: 15px;
       /* margin-right: 150px;  */
       border: solid;
       margin: unset;
       width: 100%;
       padding: 14px;

    }
    #service-sub-menu li{
        border: unset;
    }
}

@media only screen and (max-width: 950px) {

    /* .headerTop p {
    font-size: 8px;
    padding-top: 5px;
    height: 100%;
  }
   */
    .checkbtn {
        /* position: absolute;
    left: 5px;
    top: -23px; */
        margin: unset;
        /* border: solid red; */

    }

    /* .headerTop {
        font-size: 8px;
        justify-content: unset;
        display: flex;
        align-items: center;
        height: 100%;
    } */
    .headerTop{
        display: none;
    }

    #check:checked~ul {
        width: 100%;
        overflow: auto;
        /* gap: 36px; */
    }

    .black-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;
        flex-direction: column;
    }

    nav ul li a {
        font-size: 20px;
        color: #fff;
    }
}

.black-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    justify-content: space-between;
    padding-right: 29px;
    padding-left: 29px;
}

.black-header span {
    font-size: 13px;
    line-height: 111.25%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    cursor: pointer;
}

.language.active {
    color: #ecb365 !important;
}

#menuicon {
    font-size: 20px;
    margin-left: 5px;
    color: #fff;
    /* color: white; */
    margin: 0 0 5px 15px;
}

#closeicon {
    color: #fff;
    font-size: 32px;
    margin-left: 140px;
    display: none;
}

@media (max-width: 1250px) {
    #closeicon {
        display: block;
    }
}

#hide {
    display: none;
}

@media screen and (max-width:1250px) {
    #hide {
        display: inline
    }
}

#service-sub-menu {
    display: flex;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 133px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    width: 100px;
    padding: unset;
    margin: unset;
    height: 0;
    overflow: hidden;
    justify-content: flex-start;
    border-radius: 5px;
    transition: all 0.3s;


}

#service-sub-menu li {
    width: 100%;
}

#service-sub-menu li a {
    color: black;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 12px;
    height: 34px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 10px;
}

#service-sub-menu li a:hover {
    background-color: #ecb365;
    transition: 0.5s ease-in-out;
    align-items: center;
}

/* Show dropdown menu content when dropdown link is hovered over */
#dropdown:hover #service-sub-menu {
    height: fit-content;
    transition: 0.1s ease-in-out;
    width: fit-content;
    transition: all 0.5s;



}

@media screen and (max-width:1000px) {
    #service-sub-menu {
        position: relative;
        background: #041C32;
        left: -3%;
            box-shadow: unset;

    }

    #service-sub-menu li a {
        color: white;
    }

    #dropdown:hover #service-sub-menu {
        height: 100%;
    }

}

#icon {
    position: relative;
    left: 10px;
}




#closeNav{
    top: 0;
    position: sticky;
    left: 15vw;
    /* border: solid; */
    /* width: 100vw; */
    z-index: 9;
    background: #041C32;
    display: flex;
    /* border: solid; */
    flex-direction: row-reverse;
    padding-right: 19px;
    padding-top: 20px;
}

.currency-accordion {
    width: 100%;
    padding-top: 5px;
  }
  
  .currency-toggle {
    width: 100%;
    padding: unset;
    text-align: left;
    background-color: #041C32;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }
  
  .currency-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .currency-options.open {
    max-height: 500px; /* Adjust this value according to your content */
  }
  
  .currency-tab {
    width: 100%;
    padding: 10px;
    text-align: left;
    background-color: #041C32;
    border: none;
    cursor: pointer;
    font-size: 13px;
  }
  
  .currency-tab.active {
    background-color: #041C32;
  }
  






  