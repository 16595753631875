/* section.galleryContentContainer {
    padding: 50px 30px 50px 30px;
}


.nav-item a {
        font-weight: 500;
        color: #041c32;
        border-radius: unset;
        border-left: unset;
        background-color: #d6dae100;
        -webkit-box-shadow: 0px 0px 35px -21px rgba(0,0,0,.75) inset;
        -moz-box-shadow: 0px -67px 64px -21px rgba(0,0,0,.75) inset;
        box-shadow: 0px 0px 35px -21px rgba(0,0,0,.75) inset; 
        clip-path: polygon(0 0,97% 0,100% 100%,0% 100%);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
   
    
}
.nav-item-active{
     font-weight: 500;
        color: #041c32;
        border-radius: unset;
        border-left: unset;
        background-color: #d6dae100;
        -webkit-box-shadow: 0px 0px 35px -21px rgba(0,0,0,.75) inset;
        -moz-box-shadow: 0px -67px 64px -21px rgba(0,0,0,.75) inset;
        box-shadow: 0px 0px 35px -21px rgba(0,0,0,.75) inset;
        clip-path: polygon(0 0,97% 0,100% 100%,0% 100%);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    background-color: rgb(19, 88, 168);
    color: white;
    border: 1px solid rgb(19, 88, 168);
}


.nav-tabs .nav-link {
  
}

#ngb-nav-0 {
    border-left: solid 1px;
}


.nav-tabs .nav-link:focus {
    background-color: #1358a8;
    color: white;
    border: solid 1px #1358a8;
    border-radius: unset;
    box-shadow: 0px 0px 14px 0px rgba(18, 74, 140, 1) inset;

}

.nav-tabs {
    border-bottom: solid 5px #1358a8;
} */

.nav-tabss{
   
    display: flex;
    border-bottom: solid 5px #1358a8;
    position: sticky;
    z-index: 1;
    background: white;
    top: 121px;
    padding-top: 25px;
    overflow-x: auto;
    width: 100%;
    /* max-width: 1035px; */
}
@media screen and (max-width:950px) {
    .nav-tabss{
        top: 156px;
    }
}
@media screen and (max-width:1135px) {
    .nav-tabss{
        /* top: 156px; */
        border-bottom:unset
    }
}
.nav-tabss a{
    font-weight: 500;
    color: #041c32;
    border-radius: unset;
    border-left: unset;
    background-color: #d6dae100;
    -webkit-box-shadow: 0px 0px 35px -21px rgba(0,0,0,.75) inset;
    -moz-box-shadow: 0px -67px 64px -21px rgba(0,0,0,.75) inset;
    clip-path: polygon(0 0,97% 0,100% 100%,0% 100%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    width: max-content;
    /* transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out; */
}

.galleryContentContainer{
    /* padding:  30px 50px; */
    padding: 0 50px;
}
@media screen and (max-width:1035px) {
    .galleryContentContainer{
       
        padding: unset;
    }
}

.nav-item-active{
    background-color: rgb(19, 88, 168);
    color: white;
    border: 1px solid rgb(19, 88, 168);
    clip-path: polygon(0 0,97% 0,100% 100%,0% 100%);


}

.vs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    
}
