section.testimonialsSection {
    padding: 50px 50px 50px 50px;
}

.testimonialsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.testimonialsContent h1 {
    font-size: 25px;
    line-height: 111.25%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
}

.testimonialsContent h1:hover {
    color: #ECB365;
    transition: all 0.3s;
}

#agent-testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#test-img {
    width: 176px;
    height: 209px;
    border: 1px solid #041c32;
    border-radius: 2px;
    object-fit: cover;
}

.testimonialsSection h2 {
    font-weight: 800;
    font-size: 19px;
    line-height: 111.25%;
    text-align: center;
    letter-spacing: 0.115em;
    text-transform: capitalize;
    color: #041c32;
    padding: 30px 0 5px 0;
}

.owl-carousel.owl-drag .owl-item h3 {
    font-weight: 300;
    font-size: 17px;
    line-height: 111.25%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #041c32;
    padding-bottom: 30px;
}

.owl-carousel.owl-drag .owl-item p {
    font-size: 17px;
    line-height: 168.25%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.07em;
    color: #041c32;
    width: 594px;
    height: 153px;
    padding: 15px 0;
    justify-content: center;
}

/* resp */
/* @media only screen and (max-width: 740px) {
  .testimonialsContent h1 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 717px) {
  .testimonialsContent h1 {
    font-size: 25px;
  }
} */
@media only screen and (max-width: 800px) {
    .testimonialsContent h1 {
        font-size: 21px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 15px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 17px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 15px !important;
    }

    #test-img {
        width: 150px !important;
        height: 170px !important;
    }
}

@media only screen and (max-width: 700px) {
    .testimonialsContent h1 {
        font-size: 19px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 13px !important;
        width: unset;
        height: unset;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 15px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 13px !important;
    }

    #test-img {
        width: 130px !important;
        height: 150px !important;
    }
}

@media only screen and (max-width: 600px) {
    .testimonialsContent h1 {
        font-size: 17px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 11px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 13px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 11px !important;
    }

    #test-img {
        width: 110px !important;
        height: 130px !important;
    }
}

@media only screen and (max-width: 510px) {
    .testimonialsContent h1 {
        font-size: 15px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 10px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 12px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 10px !important;
    }

    #test-img {
        width: 100px !important;
        height: 120px !important;
    }
}

@media only screen and (max-width: 470px) {
    .testimonialsContent h1 {
        font-size: 13px;
    }
}

@media only screen and (max-width: 410px) {
    .testimonialsContent h1 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 385px) {
    .testimonialsContent h1 {
        font-size: 11px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 9px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 11px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 9px !important;
    }

    #test-img {
        width: 90px !important;
        height: 110px !important;
    }
}

@media only screen and (max-width: 360px) {
    .testimonialsContent h1 {
        font-size: 10px;
    }
}

@media only screen and (max-width: 340px) {
    .testimonialsContent h1 {
        font-size: 9px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 8px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 10px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 8px !important;
    }

    #test-img {
        width: 80px !important;
        height: 100px !important;
    }
}

@media only screen and (max-width: 315px) {
    .testimonialsContent h1 {
        font-size: 8px;
    }
}

@media only screen and (max-width: 290px) {
    .testimonialsContent h1 {
        font-size: 7px;
    }

    .owl-carousel.owl-drag .owl-item p {
        font-size: 6px !important;
    }

    .owl-carousel.owl-drag .owl-item h2 {
        font-size: 8px !important;
    }

    .owl-carousel.owl-drag .owl-item h3 {
        font-size: 6px !important;
    }

    #test-img {
        width: 70px !important;
        height: 90px !important;
    }
}










.owl-theme .owl-dots .owl-dot span {
    border-radius: 2px !important;
    background: #E0DBDB;
    width: 18px;
    height: 18px;


}

@media screen and (max-width:940px) {
    .owl-theme .owl-dots .owl-dot span {
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width:740px) {
    .owl-theme .owl-dots .owl-dot span {
        width: 12px;
        height: 12px;
    }
}

@media screen and (max-width:400px) {
    .owl-theme .owl-dots .owl-dot span {
        width: 9px;
        height: 9px;
    }
}

@media screen and (max-width:300px) {
    .owl-theme .owl-dots .owl-dot span {
        width: 6px;
        height: 6px;
    }
}


.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ECB365;
}




.vs-container .owl-carousel.owl-drag .owl-item {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    justify-items: center;
    justify-content: center;
    align-content: center;

}


@media screen and (max-width:800px) {
    .testimonialsSection .owl-carousel.owl-drag .owl-item {
        display: flex !important;
        flex-direction: column !important;
        gap: 16px;
        flex-wrap: wrap !important;
    }

}


.testimonialsSection .owl-theme .owl-nav [class*=owl-] {
    padding-top: 9px;
    font-size: 5px;
}

.testimonialsSection .owl-prev,
.owl-next {
    border-radius: 50% !important;
    background-color: #ECB365 !important;
    height: 47px;
    width: 47px;
}



.testimonialsSection .owl-theme .owl-nav {
    display: flex;
    justify-content: space-around;
    position: relative;
    bottom: 300px;
}

@media screen and (max-width:1327px) {
    .testimonialsSection .owl-theme .owl-nav {
        display: none;
    }
}