.example-card {
    max-width: 400px;
    height: 446px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(4, 28, 50, 0.1);
    box-shadow: -1px 1px 45px -18px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    transition: all 0.3s;
}

.example-card:hover {
    transform: scale(1.05); /* You can adjust the scaling factor according to your preference */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the shadow values as needed */
    transition: all 0.3s;
  }

.card-img{

}
.card-info{
    padding: 10px 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50%;

}
.card-title{
    color: black;
    text-transform: uppercase;
    font-family: Montserrat;
font-size: 17px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0.08em;
text-align: left;

}
.card-subtitle{
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #041C3299;

    
}