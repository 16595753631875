.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/contact_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;

    gap: 21px;
    font-family: "Manrope", serif;
    font-style: normal;
    margin: 100px 100px;
    padding-top: 40px;
}

@media screen and (max-width: 1000px) {
    .contact-container {
        margin: unset;
        padding-top: 165px;
    }
}

.contact-container h1 {
    font-weight: 700;
    font-size: 27px;
    line-height: 111.25%;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin-top: 20px;
}

.contact-container p {
    font-weight: 600;
    font-size: 19px;
    line-height: 111.25%;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-align: center;
}

.contact-container input {
    max-width: 355px;
    width: 90%;
    height: 53px;
    background: #f4f4f4;
    border-radius: 3px;
    border: unset;
    margin: 0px 10px;
}

.contact-container input::placeholder,
.contact-container textarea::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: rgba(0, 0, 0, 0.5);
    padding-left: 5px;
}

.contact-container textarea {
    max-width: 355px;
    width: 90%;
    resize: none;
}

.contact-container button {
    width: 192px;
    height: 39px;
    background: #ecb365;
    border-radius: 3px;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #041c32;
    margin-bottom: 50px;
}

form.formSubmit {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* justify-content: space-around; */
    gap: 10px;
}