.active,
a:hover {
    color: #ecb365 !important;
    transition: 0.5s;
}

.language.active {
    color: #ecb365 !important;
}

.currency-tab.active {
    background-color: #041C32;
  }
  
.elementbtn{
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 17px;
    font-style: normal;
    height: 20px;
    justify-content: center;
    letter-spacing: .02em;
    list-style: none;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #0000001a;
    font-family: "Manrope", serif;

}

.fonttype{
    font-family: "Manrope", serif;

}

.menu{
    display: none ;
}
.mobileEl{
    display: flex;
    justify-content: center;
    gap: 3vw;
}
@media screen and (max-width:1360px) {
    .menu{
        display: block !important;
    }
    .mobileEl{
        display: none;
    }
}

@media screen and (max-width:768px) {
    
.topNav{
    width: 100%;
    justify-content: space-between;
}
}