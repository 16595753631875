.not-found-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
  }
  
  .text-center {
    text-align: center;
  }
  
  .circle-background {
    display: inline-flex;
    border-radius: 50%;
    background-color: #f3f4e6;
    padding: 20px;
  }
  
  .circle-inner {
    border-radius: 50%;
    border: 4px solid #dca252;
    background-color: #ecb365;
    padding: 20px;
  }
  
  .icon {
    width: 80px;
    height: 80px;
  }
  
  .not-found-heading {
    margin-top: 20px;
    font-size: 36px;
    font-weight: bold;
    color: #4b5563;
  }
  
  .not-found-text {
    margin-top: 20px;
    font-size: 16px;
    color: #718096;
  }
  
  @media (min-width: 768px) {
    .not-found-heading {
      font-size: 50px;
    }
  }
  