.banner-container{
    background: #041c32;
    box-shadow: inset 0px -271px 77px -305px #ffffff;
    /* padding-top: 150px; */
    /* border-radius: 100% 0% 138% 32% / 0% 30% 65% 100%; */


}


.banner-title p {

    font-size: 22px;
    line-height: 111.25%;
    display: flex;
    /* align-items: ; */
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    color: #041C32;
    background-color: #ffffff;
    justify-content: center;
    height: 110px;
    padding-top: 39px;
}
/* 
@media only screen and (max-width: 600px) {

    .banner-title 
    p,
    .banner-header-title {
        font-size: 10px !important;
    }
}

@media only screen and (min-width: 600px) {

    .banner-title
    p,
    .banner-header-title {
        font-size: 13px !important;
    }
}

@media only screen and (min-width: 768px) {

    .banner-title
    p,
    .banner-header-title {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 992px) {

    .banner-title
    p,
    .banner-header-title {
        font-size: 19px !important;
    }
}

@media only screen and (min-width: 1200px) {

    .banner-title
    p,
    .banner-header-title {
        font-size: 22px !important;
    }
} */


 .banner-container .owl-dots {
    position: absolute;
    top: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

 .banner-container .owl-theme .owl-dots .owl-dot span {
    border-radius: 2px !important;
    background: #E0DBDB;
    width: 18px;
    height: 18px;
    /* position: relative;
      right: 591px;
      bottom: 430px; */

}

@media screen and (max-width:940px) {
     .banner-container .owl-theme .owl-dots .owl-dot span {
        width: 15px;
        height: 15px;
    }
   
}

@media screen and (max-width:740px) {
     .banner-container .owl-theme .owl-dots .owl-dot span {
        width: 12px;
        height: 12px;
    }
}

@media screen and (max-width:400px) {
     .banner-container .owl-theme .owl-dots .owl-dot span {
        width: 9px;
        height: 9px;
    }
}

@media screen and (max-width:300px) {
     .banner-container .owl-theme .owl-dots .owl-dot span {
        width: 6px;
        height: 6px;
    }
}


 .banner-container .owl-theme .owl-dots .owl-dot.active span,
 .banner-container .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #ECB365;
}
.banner-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 53px;
    height: 150px;
}

.banner-header-title {
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 111.25%;
    display: flex;
    justify-content: center;
    text-align: start;
    /* letter-spacing: 0.065em; */
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    padding: 25px 0 25px 0;
    width: 625px;
}

.banner-header-btn{
    width: 196.966px;
height: 42px;
flex-shrink: 0;
border-radius: 2px;
background: #ECB365;
box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
color: #041C32;
text-align: center;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 111.25%; /* 20.025px */
letter-spacing: 0.36px;
}

@media screen and (max-width:890px) {
    .banner-header{
        flex-direction: column;
        height: 100%;
        padding: 10px 0;
    }
    .banner-header-title{
        width: 100%;
        text-align: center;
        font-size: 15px;
    }
}

 .owl-carousel .owl-item img{
    width: 100vw;
max-height: 65vh;
}