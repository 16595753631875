.so-container {
    padding: 40px 0;
}

.so-container .element {
    display: flex;
    flex-direction: column;
    align-items: center;
  
}

.element {
    margin: 50px;
}

.element h3 {
    margin-bottom: 25px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-align: center;

    text-transform: uppercase;

}
.MuiTypography-root {
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    font-family: 'Montserrat' !important;
    font-size: 22px !important;
    font-weight: 600 !important;
}
.MuiDialog-paper{
    margin: 0;
}
/* .textarea-element {
   
    width: 773px;
    height: 170px;
border-radius: 3px;
border: 1px solid #041C3299;
box-shadow: 0px 2px 11.699999809265137px 0px #00000026;


} */
.textarea-element {
    border: none;
    outline: none;
    /* max-width: 773px; */
    width: 100%;
        /* height: 170px; */
height: 140px;
    text-align: left;
    vertical-align: top;
    line-height: normal;
    border-bottom: 1px solid #041C3299;    /* Ajoutez cette ligne pour ajuster la hauteur de ligne */
    padding: 20px;
    line-height: 20px;
    font-family: "Nunito";
}

.textarea-element::placeholder{
    padding-left: 10px;
}

.GrayBand {
    max-width: 900px;
    width: 100%;
    height: 50px;
    /* background: linear-gradient(0deg, rgba(4, 28, 50, 0.6), rgba(4, 28, 50, 0.6)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)); */
    background: #cccccc4d;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
}

@media screen and (max-width:440px) {
    .GrayBand{
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        gap: 10px;
    }
}
.file-list-iamge-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min-content;
}
.file-list-title{
    font-size: 10px;
    font-weight: 600;
    /* border: solid; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100px;
}

.upload-icon-btn {
    /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 201px;
    height: 30px;
    border-radius: 3px;

    border: 1px solid #00000080;
    background-color: #fff; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 230px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #00000080;
    background-color: #fff;
}
.upload-icon-btn:hover {
    cursor: pointer;
  }
  
.upload-validation-btn {

    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.065em;
    text-align: left;
    background: #FFFFFF;

    width: 100px;
    height: 29px;
    top: 1304px;
    left: 4688px;
    border-radius: 3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(0deg, #044C7B, #044C7B);
    border: 1px solid #00000080;
}

.upload-btn {
    width: 165px;
    height: 29px;
    top: 1304px;
    left: 4688px;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.065em;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    background-color: transparent;

}

.imgs-notes {
    border-radius: 3px;
    border: 1px solid #041C3299;
    /* box-shadow: 0px 2px 11.699999809265137px; */
    /* box-shadow: 0px 2px 11.699999809265137px; */
    width: 100%;
    max-width: 900px;
    margin-top: 10px;

}


@media screen and (max-width:1000px) {
    .imgs-notes{
        width: 100vw;
        max-width: 100vw;
        box-shadow: none;
    }
}


.back-btn {
    width: 232.51px;
    height: 56px;
    top: 1279px;
    left: 3190px;
    border-radius: 3px;
    background: #FF9401;
    color: white;

    /* font-size: 20px; */
}

.submit-btn {
    width: 232.51px;
    height: 56px;
    top: 1279px;
    left: 3537px;
    border-radius: 3px;
    background: #18BD5B;
    color: white;
}

#payment-element {
    margin-top: 0px;
    background-image: url('../../../assets/img/LOGO s blue 3.png');
    background-size: 100% 100%;
    margin-bottom: 50px;

}

.payement h3 {
    margin-top: 50px;
}

.payement {
    margin-bottom: 20px
}




.element-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* padding-top: 70px; */
    width: 100%;

}

.payement-element {
    display: flex;
    flex-direction: column;
}

#styles {
    width: 310px;
    height: 40px;
    top: 217px;
    left: 3244px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid #041C3299;
    box-shadow: -1px 1px 45px -33px #00000033;
    /**/
    font-family: Nunito;
    font-size: 15px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000000;
    /**/
}

.info-element {
    /* width: 1091px;
height: 23px;
top: 1050px;
left: 4588px;
font-family: Nunito;
font-size: 17px;
font-weight: 600;
line-height: 23px;
letter-spacing: 0.065em;
text-align: center;
color: #041C32B2; */
max-width: 866px ;
    width: 100%;
    /* height: 23px; */
    top: 1050px;
    left: 4588px;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.009em;
    text-align: center;
    color: #041C32B2;

}

#uploader-info {
    width: 962px;
}

/* .info-note{
    font-size: 18px;
} */

#VS-container p {
    width: 605px;
    height: 19px;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
}
.submit-btn.disabled {
    cursor: not-allowed;
  }
  

/* resp */
@media screen and (max-width: 700px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 550px) {}

@media screen and (max-width: 500px) {}

@media screen and (max-width: 450px) {}

@media screen and (max-width: 400px) {}

@media screen and (max-width: 350px) {}

@media screen and (max-width: 325px) {}

@media screen and (max-width: 290px) {}




@media screen and (max-width: 1000px) {}


@media screen and (max-width: 1000px) {}

.desktop {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.mobile {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

/* .MuiDialog-paperFullWidth{
    height: 100vh;
    max-height: 100% !important;
} */
/* ::-webkit-scrollbar-thumb{
    width: 100%;
}
::-webkit-scrollbar-thumb{
    background-color: unset !important;
    width: 100%;
    height: 100%;
    border-radius: unset;
} */