.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
    border-radius: 4px;
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 1s infinite;
    content: '';
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
   }
}
.blog-post__headline {
    font-size: 1.25em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 31px;
    margin-top: 22px;
}
.blog-post__meta {
    font-size: 0.85em;
    color: #6B6B6B;
}
.o-media {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.o-media__body {
    flex-grow: 1;
    margin-left: 1em;
    width: 100%;
}
.o-vertical-spacing > * + * {
    margin-top: 0.75em;
}
.o-vertical-spacing--l > * + * {
    margin-top: 2em;
}
.skeleton-p {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}