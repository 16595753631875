.success-message {
  text-align: center;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-message__icon {
  max-width: 75px;
}

.success-message__title {
  color: black;
  transform: translateY(25px);
  /* opacity: 0; */
  transition: all 200ms ease;
  font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 111.25%;
}

.active .success-message__title {
  transform: translateY(0);
  opacity: 1;
}

.success-message__content {
  color: black;
  transform: translateY(25px);
  /* opacity: 0; */
  transition: all 200ms ease;
  transition-delay: 50ms;
}

.active .success-message__content {
  transform: translateY(0);
  opacity: 1;
}

.icon-checkmark circle {
  fill: #3dc480;
  transform-origin: 50% 50%;
  /* transform: scale(0); */
  transition: transform 200ms cubic-bezier(.22, .96, .38, .98);
}

.active .icon-checkmark circle {
  transform: scale(1);
}
