.partners-container {
    padding: 40px 0 60px 0;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.partners-title {
    font-weight: 700;
    font-size: 27px;
    line-height: 111.25%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 30px 0 30px 0;
    color: #000000;
}

.partners-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 36px 68px;
}

.oneImg {
    max-width: 150px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.partner-container {
    display: flex;
    justify-content: center;
    height: 100px;
    width: 100%;
  


}


.owl-theme .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
   

}


@media screen and (max-width: 940px) {
    /* .owl-theme .owl-dots .owl-dot span {
        width: 15px;
        height: 15px;
    } */
    .partners-title {
        font-size: 21px;
    }
}

@media screen and (max-width: 740px) {
    /* .owl-theme .owl-dots .owl-dot span {
        width: 12px;
        height: 12px;
    } */
    .partners-title {
        font-size: 19px;
    }
}

@media screen and (max-width: 400px) {
    /* .owl-theme .owl-dots .owl-dot span {
        width: 9px;
        height: 9px;
    } */
    .partners-title {
        font-size: 15px;
    }
}

@media screen and (max-width: 300px) {
    /* .owl-theme .owl-dots .owl-dot span {
        width: 6px;
        height: 6px;
    } */
    .partners-title {
        font-size: 13px;
    }
}

@media screen and (max-width: 1327px) {
    .owl-theme .owl-nav {
        display: none;
    }
}