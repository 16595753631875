.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px;
}

.btn-container button {
    width: 165px;
    height: 40px;
    border-radius: 3px;
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.015em;
    text-align: center;
    white-space: nowrap; 
}

.changes-btn {
    background: #FF9401;
    margin-right: 25px;
}

.validation-btn {
    background: #18BD5B;
    margin-left: 25px;
}
