.formuser-add{
    display: flex;
    flex-direction: column;
    padding: 20px 0;

}
.user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  
  .form-group {
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-bottom: 10px;
  }
  
  .add-user-btn {
    margin-top: 10px;
    background-color: forestgreen;
    color: #fff;
  }
  .users-table-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
  