.offerOrder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 77px;
    background: #041c32;
}

.offerOrder p {
    color: #ffffff;
    text-shadow: 0px 1px 5px rgba(236, 179, 101, 0.4);
    letter-spacing: 0.02em;
    line-height: 141.75%;
    font-size: 15px;
}

.offerOrder button {
    background: #ecb365;
    box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    letter-spacing: 0.02em;
    color: #041c32;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.1);
    font-size: 16px;
    width: fit-content;
    height: 29px;
    padding: 5px;
}

/******************************************/
@media (max-width: 1000px) {
    .offerOrder button {
        font-size: 16px;
    }

    .offerOrder p {
        font-size: 15px;
    }
}

@media (max-width: 950px) {
    .offerOrder button {
        font-size: 15px;
    }

    .offerOrder p {
        font-size: 14px;
    }
}

@media (max-width: 880px) {
    .offerOrder button {
        font-size: 14px;
    }

    .offerOrder p {
        font-size: 13px;
    }
}

@media (max-width: 820px) {
    .offerOrder button {
        font-size: 13px;
    }

    .offerOrder p {
        font-size: 12px;
    }
}

@media (max-width: 750px) {
    .offerOrder button {
        font-size: 12px;
    }

    .offerOrder p {
        font-size: 11px;
    }
}

@media (max-width: 700px) {
    .offerOrder {
        height: 60px;
    }

    .offerOrder button {
        font-size: 12px;
    }

    .offerOrder p {
        font-size: 10px;
    }
}

@media (max-width: 630px) {
    .offerOrder button {
        font-size: 11px;
    }

    .offerOrder p {
        font-size: 9px;
    }
}

@media (max-width: 585px) {
    .offerOrder {
        height: 50px;
    }

    .offerOrder button {
        height: 25px;
        font-size: 10px;
    }

    .offerOrder p {
        font-size: 8px;
    }
}

@media (max-width: 515px) {
    .offerOrder {
        height: 40px;
    }

    .offerOrder button {
        height: 24px;
        font-size: 10px;
    }

    .offerOrder p {
        font-size: 7px;
    }
}

@media (max-width: 485px) {
    .offerOrder button {
        height: 22px;
        font-size: 9px;
    }

    .offerOrder p {
        font-size: 6.7px;
    }
}

@media (max-width: 450px) {
    .offerOrder button {
        height: 20px;
        font-size: 8px;
    }
}

@media (max-width: 400px) {
    .offerOrder button {
        height: 18px;
        font-size: 8px;
    }

    .offerOrder p {
        font-size: 6px;
    }
}

@media (max-width: 370px) {
    .offerOrder {
        height: 30px;
    }

    .offerOrder button {
        height: 16px;
        font-size: 8px;
    }

    .offerOrder p {
        font-size: 5px;
    }
}

@media (max-width: 325px) {
    .offerOrder p {
        font-size: 4.3px;
    }
}