.articles-container {
    max-width: 1050px;
    background: #F8F7F7;
    overflow-y: auto;
    height: 500px;
    margin: 10px auto 50px auto;
    border: 1px solid #00000033;
    width: 100%;
    }


.articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(236px, 1fr));
    padding: 53px;
    row-gap: 35px;
    justify-items: center;
}

article {
    position: relative;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border: 1px solid #041C3280;
    border-radius: 3px;
    max-width: 240px;
    color: #34495e;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.bigger-article {
    /* transform: scale(1.2) rotateX(5deg); */
}


article a::after {
    position: absolute;
    inset: 0;
    cursor: pointer;
    content: "";
}

article h2 {
    margin: 0 0 18px 0;
    font-family: "Bebas Neue", cursive;
    font-size: 1.9rem;
    letter-spacing: 0.06em;
    color: var(--title-color);
    transition: color 0.3s ease-out;
    text-decoration: underline;
}

figure {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

article img {
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
    width: 100%;
    height: 155px;
    
}

.article-body {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100px;
}

.article-body h2 {
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-align: left;
}

.article-body p {
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #041C3299;
    font-size: bold;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.read-more {
    font-family: Nunito;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #006FB7;
    margin-top: 20px;
    text-decoration: underline;
    font-size: 14px;
    /* margin-left: 140px; */
}

article a:focus {
    outline: 1px dotted #28666e;
}

article a .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    transform: translateX(var(--link-icon-translate));
    opacity: var(--link-icon-opacity);
    transition: all 0.3s;
}

@media screen and (max-width: 960px) {
    /* article {
        container: card/inline-size;
    } */

    .article-body p {
        display: none;
    }
}

@container card (min-width: 380px) {
    .article-wrapper {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 16px;
    }

    .article-body {
        padding-left: 0;
    }

    figure {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    figure img {
        height: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }
}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}


.liste .articles {
    display: flex;
    flex-direction: column;
}


.liste article {
   
    max-width: 100%;
    /* height: 60px; */
    
}

.liste .bigger-article {
    transform: none;
}



.article-body-list{
display: flex;
flex-direction: column;
}

.building-icon{
    align-self: center;
}

.article-body-list p {
    font-size: 15px;
    }
.article-body-list h2 {
  
    font-size: 15px;
}

.circular-image-container {
    width: 50px; 
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
  }
  
  .circular-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  








.article-wrapper-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin: 0 10px;
}
.article-body-list {
    display: flex;
    flex-direction: column;
    margin-left: 17px;
}

