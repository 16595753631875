.order-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.order-details-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1041px;
}

.order-details-title-details {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.order-details-title h1 {
  text-align: center;
  padding-bottom: 50px;
}

.order-details-title h2 {
  width: 186px;
  height: 19px;
  top: -406px;
  left: 1610px;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-align: center;
}

.order-details-title p {
  width: 579px;
  height: 17px;
  top: -403px;
  left: 1792px;
  font-family: Nunito;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.02em;
}


.order-details-body {
  display: flex;
  align-items: center;
  padding: 50px 0;
  width: 100%;
  max-width: 1041px;
  justify-content: center;

}