FileManager.css

/* Table styles */
table {
    
    width: 1002px;
    height: 767px;
    top: 1484px;
    left: 6518px;
    border-radius: 3px;
    border: 1px solid #00000033;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #00000033;
    padding: 8px;
  }

  th {
    background-color: #F1F1F1;
  }
  
  /* Bottom section styles */
  #bottomSection {
    background-color: #D9D9D91A;
    width: 1002px;
    top: 1677px;
    left: 6518px;
    border: 1px solid #00000033;
    border-top: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  /* File input and button styles */
  button {
    background-color: #D9D9D91A;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
 

  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    top: 1629px;
    left: 7031px;
    border-radius: 3px;
    border: 1px solid #00000033;
  }

  select {
    width: 178px;
    height: 32px;
    top: 1626px;
    left: 6774px;
    border-radius: 3px;
    border: 1px solid #35353599;
    font-family: "Manrope", serif, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.065em;
    text-align: left;
    padding: 5px;
  }
  /* Name field styles */
td:nth-child(2) {
    font-family: "Manrope", serif, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.065em;
    text-align: left;
    color: #000000;
    width: 65px;
    height: 20px;
    top: 1632px;
    left: 6604px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /* Comment input styles */
td:nth-child(5) input[type="text"] {
    width: 358px;
    height: 43px;
    top: 1620px;
    left: 7211px;
    border-radius: 3px;
    border: 1px solid #00000033;
    background: #faf8f8;
    padding: 8px;
  }
  
  /* Placeholder styles */
  td:nth-child(5) input[type="text"]::placeholder {
    font-family: "Manrope", serif, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.065em;
    text-align: left;
    color: #00000066;
  }

  /* Title styles */
th {
    font-family: "Manrope", serif, sans-serif;
    width: 101px;
    height: 20px;
    top: 1568px;
    left: 7031px;
    color: #353535CC;
    padding: 8px;
  }


/* Add Files button styles */
#bottomSection button {
    /* width: 170px; */
    height: 43px;
    top: 2187px;
    left: 7191px;
    border-radius: 3px;
    border: 1px solid #00000080;
  }
  
  /* Text inside the button styles */
  #bottomSection button span {
    /* width: 98px; */
    height: 23px;
    top: 2197px;
    left: 7227px;
    font-family: "Manrope", serif, sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.065em;
    text-align: left;
    color: #000000;
  }

  #bottomSection button:first-child {
    white-space: nowrap;
  }
  #bottomSection button:first-child span {
    text-align: center;
    display: inline-block;
    width: 100%;
  }