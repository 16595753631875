.owl-carousel {
  display: flex;
  align-items: center;
}

.owl-stage {
  display: flex;
  align-items: center;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}
/* @media screen and (min-width: 1280px) {
  .img-container img{
    width: 1050px !important;
  }
} */

.bf-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Manrope", serif;
  font-style: normal;
  color: #fff;
  background-image: url('../../../../assets/img/contact_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 50px;
}

.bf-secret {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 965px;
  gap: 55px 114px;
  margin-bottom: 50px;
}

.bf-secret img {
  margin-right: 20px;
  max-width: 200px;
}

.bf-secret span {
  display: flex;
}

.bf-secret h3 {
  font-weight: 600;
  font-size: 17px;
  line-height: 141.75%;
  letter-spacing: 0.02em;
  width: 100%;
  color: #ffffff;
  ;
  max-width: 272px;
}


.bf-container-header p {
  margin-bottom: 10px;
}
.bf-container-header h1 {
  margin-bottom: 80px;
  margin-top: 80px;
}

@media only screen and (max-width: 1280px) {
  .bf-secret {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .bf-secret span {
    display: flex;
    flex-direction: row;
  }

}


.owl-theme-container .owl-dots{
  display: flex;
  flex-direction: row;
  justify-content: center
}


.owl-theme-container .owl-dots .owl-dot span {
  border-radius: 2px !important;
  background: #E0DBDB;
  width: 18px;
  height: 18px;
  margin: 0 10px 20px 10px;
}


@media screen and (max-width:940px) {
  .owl-theme-container .owl-dots .owl-dot span {
      width: 15px;
      height: 15px;
  }

}
@media screen and (max-width:740px) {
  .owl-theme-container .owl-dots .owl-dot span {
      width: 12px;
      height: 12px;
  }
}
@media screen and (max-width:400px) {
  .owl-theme-container .owl-dots .owl-dot span {
      width: 9px;
      height: 9px;
  }
}

@media screen and (max-width:300px) {
  .owl-theme-container .owl-dots .owl-dot span {
      width: 6px;
      height: 6px;
  }
}
.owl-theme-container .owl-dots .owl-dot.active span,
.owl-theme-container .owl-dots .owl-dot:hover span {
  background-color: #ECB365;
}
.bf-container .owl-theme-container .owl-nav [class*=owl-] {
  padding-top: 9px;
  font-size: 5px;
}
.bf-container .owl-theme-container .owl-nav {
  display: flex;
  justify-content: space-around;
  position: relative;
  bottom: 357px;
  gap: 600px;
}

@media screen and (max-width:1327px) {
  .bf-container .owl-theme-container .owl-nav {
      display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .carousel-controls{
    display: none !important;
  }
}
.carousel-controls {
  display: flex;
  justify-content: center;

  width: 100%;

}

.carousel-arrow {
  background: none;
  border: none;
  cursor: pointer;
  /* position: relative; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  z-index: 10;
}

.carousel-arrow img {
  width: 30px;
  height: 30px;
}

/* .carousel-arrow.left {
  left:-10vw; 
}

.carousel-arrow.right {
  right: -10vw; 
} */


.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
/* Default dot styles */
.carousel-dots button {
  background-color: #ccc; /* Default color for dots */
  border: none;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Style for active dot */
.carousel-dots button.carousel__dot--selected {
  background-color: #ECB365; /* Change this to your preferred color */
}


.slideInner___2mfX9 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: auto;


}
slideInner___2mfX9:focus-visible{
  border: none !important;
}

.carousel{
  width: 100%;
}

.focusRing___1airF ,.second{
  outline-style: unset !important;
}


.responsive-slider {
  width: auto !important;
  max-width: 712px;
}

@media screen and (min-width:768px) {
  .responsive-slider{
    width: 712px;
  }
}


@media (max-width: 480px) {
  .responsive-slider {
    width: 300px !important;
    /* max-width: 100%; */
    max-height: 300px; /* Adjust for smaller screens */
  }
  .vsImages img {
    max-height: 300px;
    width: 100% !important;
  }
}
