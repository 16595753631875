.upload-input-admin {
    width: 520px;
    height: 230px;
    top: -390px;
    left: 3009px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 2px;
    background: #ffff;
    border: 2px dotted  #1358A8;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
 /* top: -63px;
    left: 3009px; */
    gap: 5px;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* Adjust this value to add space between lines */
    letter-spacing: 0.02em;

    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
}

  
  .file-list-admin {

    display: flex;
    flex-direction: row;
  }

  .file-list-admin li {
    flex-basis: calc(20% - 10px); 
    margin-bottom: 10px;
    list-style: none;
  }
  
  /* Add any additional styles for file icons, names, and buttons */
  

.upload-img-admin {
    width: 99.79px;
    height: 100px;
    top: -335px;
    left: 3429.12px;
    padding: 14.06px, 3.08px, 14.06px, 6.08px;

}

.text-fin-admin {
    font-family: Nunito;
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-align: center;
}

.text-gras {
    font-family: Nunito;
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-align: center;

    /* font-family: Nunito;
font-size: 17px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0.03em;
text-align: center;

 */

}


.fileUploader .fileContainer {
    background: none;
    box-shadow: none;
}

.infos {
    color: #00000099;
    width: 1000px;
    /* height: 102px; */
    top: -63px;
    left: 3009px;
    gap: 5px;
    font-family: Nunito;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px; /* Adjust this value to add space between lines */
    letter-spacing: 0.02em;
    text-align: center;

}
