/* AdminTestimonial.css */
.small-image {
    width: 50px; /* ou toute autre taille que vous souhaitez */
    height: auto; /* Assurez-vous que la hauteur est automatique pour maintenir les proportions de l'image */
  }
  .btn-icon {
    width: 50px;
    display: inline-block;
  }
  
  .admin-testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 15px;

  }
  
  .testimonial-form {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .input-group input,
  .input-group textarea {
    width: 200px;
    margin-right: 20px;
  }
  
  .input-group input[type="file"] {
    width: auto; /* Pour revenir à la largeur par défaut */
  }
  
  .testimonial-list {
    width: 100%;
    text-align: center;
  }
  
  .testimonial-list table {
    margin: 0 auto;
    width: 80%;
  }
  
  .testimonial-list th,
  .testimonial-list td {
    padding: 10px;
  }
  
  .small-image {
    width: 50px;
    height: 50px;
  }
  
  .edit-btn {
    background-color: orange;
  }
  
  .delete-btn {
    background-color: red;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-btn {
    background-color: #041C32;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-style: normal;
}

.page-btn:hover {
    background-color: #022647;
}
