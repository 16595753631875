.owl-carousel {
  width: 100%;
  margin: 0 auto;
}

.staged-listing-container {
  background-color: #041c32;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.owl-theme .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-prev,
.owl-next {
  border-radius: 50% !important;
  background-color: #ecb365 !important;
  height: 47px;
  width: 47px;
}

.owl-prev {
  left: 10px;
}

.owl-next {
  right: 10px;
}

.owl-dots {
  text-align: center;
}
.owl-dot.active {
  background-color: #fff;
}

.staged-listing-container {
  background-color: #041c32;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.item {
  padding: 0 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

#image-item {
  /* max-width: calc(33.33% - 70px); */
  margin-bottom: 20px;
    border-radius: 16px !important;

}

#first-image-item {
  max-width: 500px;
  margin-bottom: 20px;
  border-radius: 16px !important;
}

.staged-listing-container {
  background-color: #041c32;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.vs-title {
  font-size: 22px;
  line-height: 111.25%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.065em;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #041c32;
  justify-content: center;
  height: 70px;
}

.recent-listner {
  display: flex;
  gap: 20px;
  position: relative;
  justify-content: flex-end;
  margin-right: 7.5px;
}
#recent-listner {
  width: 102px;
  height: 115px;
  display: flex;
  justify-self: end;
}

#recent-listner-company {
  width: 113px;
  height: 61px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin: auto 0 50px 0;
}

.item-container {
  display: flex;
  flex-direction: column;
}
.owl-theme .owl-nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-prev,
.owl-next {
  border-radius: 50% !important;
  background-color: #ecb365 !important;
  height: 47px;
  width: 47px;
}

.owl-prev {
  left: 10px;
}

.owl-next {
  right: 10px;
}

.recent-listner-info {
  display: flex;
  flex-direction: column;
  text-transform: capitalize !important;
  align-items: flex-start;
  margin-left: 7.5px;
}

@media only screen and (max-width: 850px) {
  #recent-listner {
    width: 60px;
    height: 72px;
  }
  #recent-listner-company {
    width: 75px;
    height: 40px;
  }
  #first-image-item {
    max-width: 415px;
    margin-bottom: 20px;
    padding: 10px;
  }
}
/* @media only screen and (max-width: 600px) {
  #first-image-item {
    width: 415px;
  }
} */

@media only screen and (max-width: 1280px) {
  .vs-title,
  p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  .vs-title,
  p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) {
  .vs-title,
  p {
    font-size: 19px;
  }
}

@media only screen and (min-width: 1200px) {
  .vs-title,
  p {
    font-size: 22px;
  }

}
@media screen and (max-width: 940px) {
  .owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 740px) {
  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 400px) {
  .owl-theme .owl-dots .owl-dot span {
    width: 9px;
    height: 9px;
  }
}

@media screen and (max-width: 300px) {
  .owl-theme .owl-dots .owl-dot span {
    width: 6px;
    height: 6px;
  }
}

@media screen and (max-width: 1327px) {
  .owl-theme .owl-nav {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .staged-listing-container .lazy-load-image-background{
    /* border: solid red; */
    /* width: 500px; */
    max-width: calc(33.33% - 70px);
  }
}


