.admin-staged-listing-container {
    text-align: center;
    padding: 70px 15px;
  }
  
  .add-item-section {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .add-item-section > * {
    margin-right: 10px;
  }
  
  .green-btn {
    background-color: green;
    color: white;
  }
  
  .red-btn {
    background-color: red;
    color: white;
  }
  
  .recent-items-table {
    margin: 0 auto 50px;
  }
  
  .preview-images {
    margin-top: 20px;
  }
  
  .preview-image {
    max-width: 100px;
    margin-right: 10px;
  }
  
  .error-message {
    color: red;
  }
  
  .image-list{
    display: grid;
    grid-template-columns: 200px 200px 200px;
    gap: 10px;
    list-style: none;
  }
  .table-image{
    width: 200px;

  }