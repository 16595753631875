.inputs-container {
  width: 100%;
  margin-bottom: 50px;
}
.signup-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 100%;
}

.signup-container {
  margin: 50px 0 250px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.signup-title {
  padding-bottom: 20px;
  font-family: Nunito;
  font-size: 27px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;
  display: initial;
}

.su-input {

  background: #f4f4f4;

  height: 40px;
  border: solid 1px gray;
  border-radius: 3px;


  max-width: 355px;
    width: 100vw;
    height: 53px;
    border-radius: 3px;
    background: #F4F4F4;
    padding-left: 10px;
    border: solid 1px;
}




::placeholder {
  position: absolute;
  left: 10px;
}
.signup-input-groupe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.signup-input {
  margin-right: 20px;
  margin-bottom: 50px;
  margin: 15px 50px;
  /* height: 75px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signup-input label {
  margin-bottom: 3px;
  min-width: 140px;
  display: inline-block;
  text-align: left;
  height: 17px;
  font-family: Nunito;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.submit-btn {
  /* background: #ecb365;
  color: #041c32; */
  width: 170px;
  height: 53px;
  border-radius: 3px;
  margin-top: 30px;
  font-size: 15px;
}

.error-message {
  color: red;
  max-width: 202px;
  font-size: 11px;
}

.recaptcha-error {
  border: 2px solid red;
  height: 79px;
  width: 305px;
  border-radius: 4px;
}

.submit-btn-CPW {
  background: #ECB365;
  color: #041C32;
  width: 170px;
  height: 53px;
  border-radius: 3px;
  margin-top: 10px;
  max-width: 130px;
  width: 100%;
}

.react-tel-input .form-control{
  max-width: 355px !important;
  width: 100vw !important;
  height: 53px !important;
  border-radius: 3px !important;
  background: #F4F4F4 !important;
  /* padding-left: 10px !important; */
  border: solid 1px !important;
}
.react-tel-input .selected-flag{
  border: solid 1px !important;

}
.react-tel-input .form-control::placeholder{
  padding-left: 40px;
}

.MuiInputBase-root::after{
  border: red !important;
}

.react-tel-input .flag-dropdown{
  border: none !important;
}