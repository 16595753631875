@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

.why-us-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.why-us-container .title {
    margin-top: 70px;
    margin-bottom: 20px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 111.25%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;
}

.why-us-container p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 111.25%;
    letter-spacing: 0.02em;
    margin-bottom: 50px;
    text-align: center;
    color: #041c32;
}

.why-us-elements-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

}

.why-us-element {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 300px;
    height: 220px;
}

.why-us-element svg {
    width: 70px;
    height: 80px;
    margin-bottom: 8px;
}

.why-us-element h3 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 111.25%;
    text-transform: uppercase;
    color: #041c32;
}

.why-us-element p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 141.75%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #041c32;
    margin-top: 20px;
    width: 190px;
    height: 60px;
}

/* .why-us-element-container{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
} */

@media only screen and (max-width: 1280px) {
  
.why-us-element {
    /* border: 2px solid black; */
    width: 100%;
    height: 220px;
   }

.why-us-element svg {
    max-width: 100%;
    max-height: 100%;
}
  }