.forgetten-pwd-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 250px 0;
    width: 100%;
}

.forgetten-pwd-container input{
    width: 355px;
    height: 53px;
    border-radius: 3px;
    background: #F4F4F4;
    margin: 10px;
    border: none;
}
.forgetten-pwd-container button{
    background: #ECB365;
    color: #041C32;
    width: 170px;
    height: 53px;
    border-radius: 3px;
    margin-top: 30px;
}
.forgetten-pwd-container p {
    font-size: small;
}